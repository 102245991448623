<template>
    <v-card
        height="135"
        class="judge-item background pa-2 elevation-0 rounded-lg d-flex align-center flex-column justify-center p-relative"
        :class="{ display: display }"
        @click="select(judge)"
    >
        <!-- <template v-if="judge.judge">
            <template v-if="judge.score">
                <v-avatar class="rounded-lg success mb-3">
                    <h4 class="white--text">{{ judge.score.data.score }}</h4>
                </v-avatar>
            </template>

            <template v-else>
                <v-avatar class="rounded-lg light mb-3">
                    <v-icon>mdi-account-outline</v-icon>
                </v-avatar>
            </template>

            <template v-if="display">
                <b class="text-center text-overflow pa-0">Judge {{ judge.chair }}</b>
            </template>

            <template v-else>
                <b class="text-center text-overflow pa-0">{{ judge.judge.title }}</b>
            </template>

            <v-btn class="p-absolute elevation-0 bottom-10 zindex-1" color="error" x-small @click="remove(judge)" :loading="loading" v-if="!judge.score && !display">Remove</v-btn>
        </template>

        <template v-else>
            <p class="ma-0">Judge</p>
            <h1 class="text-center">{{ judge.chair }}</h1>
        </template> -->
        <template v-if="judge.judge">
            <template v-if="judge.score">
                <v-avatar class="rounded-lg success" width="80" height="80">
                    <h1 class="white--text">{{ judge.score.data.score }}</h1>
                </v-avatar>
            </template>

            <template v-if="!judge.score && judge.prevScore">
                <small>Previous</small>
                <v-avatar class="rounded-lg success" width="60" height="60" :style="{ opacity: 0.3 }">
                    <h1 class="white--text">{{ judge.prevScore.data.score }}</h1>
                </v-avatar>
                <small class="text-center line-clamp-2 pa-0">{{ judge.judge.title }}</small>
            </template>

            <template v-else>
                <template v-if="display">
                    <!-- <h2 class="text-center line-clamp-2 pa-0">Judge {{ judge.chair }}</h2> -->
                    <h2 class="text-center line-clamp-2 pa-0">
                        <!-- <v-icon x-large color="white">mdi-square-rounded</v-icon> -->
                        __
                    </h2>
                </template>

                <template v-else>
                    <h2 class="text-center line-clamp-2 pa-0">{{ judge.judge.title }}</h2>
                </template>
            </template>

            <v-btn
                class="p-absolute elevation-0 bottom-10 zindex-1"
                color="error"
                x-small
                @click="remove(judge)"
                :loading="loading"
                v-if="!judge.score && !display"
                >Remove</v-btn
            >
        </template>

        <template v-else>
            <p class="ma-0">Judge</p>
            <h1 class="text-center">{{ judge.chair }}</h1>
        </template>
    </v-card>
</template>

<script>
export default {
    name: "judge-item",

    props: {
        judge: {
            type: Object,
        },
        display: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        remove(judge) {
            this.loading = true;
            this.$emit("remove", judge);
        },
        select(judge) {
            if (!judge.judge) {
                this.loading = false;
                this.$emit("select", judge);
            }
        },
        bg(color) {
            return {
                backgroundColor: `${color} !important`,
            };
        },
    },
};
</script>

<style lang="scss">
.judge-item {
    &.display {
        background-color: #333333 !important;

        b,
        p,
        h1,
        h2,
        h3,
        h4 {
            color: #ffffff !important;
        }
    }
}
</style>
{ "judge": { "_id": "6448acabb483495936e7cbeb", "_type": "persona", "realms": [ "6449fc2f18777213cf30c653",
"6279dd9b8af46806dbd7b2af" ], "status": "active", "firstName": "Matthew", "title": "Matthew Admin", "lastName": "Admin",
"created": "2023-04-26T04:46:35.388Z", "updated": "2023-07-04T22:35:41.487Z" }, "chair": 1, "score": { "_id":
"64a5ffba73546f06d549847a", "status": "active", "hashtags": [], "_references": [], "mentions": [], "keywords": [
"sandbox - live" ], "realms": [ "64407e4b87fc285b5075bd18" ], "tags": [], "title": "Score for
2023_robyn-test-print-comp_people-print_robyn-campbell_image-1.jpg", "data": { "score": 10, "round": 3, "submitted":
true, "live": true }, "managedAuthor": "6448acabb483495936e7cbeb", "definition": "score", "updatedBy": "Matthew Admin",
"created": "2023-07-05T23:41:46.503Z", "updated": "2023-07-05T23:41:46.541Z", "slug":
"score-for-2023-robyn-test-print-comp-people-print-robyn-campbell-image-1-jpg-lgg8ooaxl", "firstLine": "" },
"prevScore": { "_id": "64a5ffba73546f06d549847a", "status": "active", "hashtags": [], "_references": [], "mentions": [],
"keywords": [ "sandbox - live" ], "realms": [ "64407e4b87fc285b5075bd18" ], "tags": [], "title": "Score for
2023_robyn-test-print-comp_people-print_robyn-campbell_image-1.jpg", "data": { "score": 10, "round": 3, "submitted":
true, "live": true }, "managedAuthor": "6448acabb483495936e7cbeb", "definition": "score", "updatedBy": "Matthew Admin",
"created": "2023-07-05T23:41:46.503Z", "updated": "2023-07-05T23:41:46.541Z", "slug":
"score-for-2023-robyn-test-print-comp-people-print-robyn-campbell-image-1-jpg-lgg8ooaxl", "firstLine": "" } }
