import FluroContent from "./fluro-content";

class Room extends FluroContent {
    constructor() {
        super("room");
    }
}

const _Room = new Room();
export default _Room;
