import FluroContent from "./fluro-content";

class ScoreCard extends FluroContent {
    constructor() {
        super("scoreCard");
    }
}

const _ScoreCard = new ScoreCard();
export default _ScoreCard;
