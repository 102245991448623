<template>
    <div>
        <v-card class="pa-3 rounded-lg elevation-0 d-flex align-center cursor-pointer" @click="select(entry)">
            <div class="flex-shrink-1 mr-4">
                <template v-if="hasImage(entry)">
                    <v-img
                        class="tile-image rounded-lg grey"
                        width="40"
                        height="40"
                        cover
                        :src="$fluro.asset.imageUrl(entry.data.image)"
                    >
                        <template v-slot:placeholder>
                            <v-row class="fill-height grey ma-0" align="center" justify="center">
                                <v-progress-circular
                                    :width="2"
                                    :size="20"
                                    color="dark"
                                    indeterminate
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </template>
                <template v-else-if="entry.data.videoSourceFull">
                    <v-img
                        class="tile-image rounded-lg grey"
                        width="40"
                        height="40"
                        cover
                        :src="getThumbnailFromEmbedUrl(entry.data.videoSourceFull)"
                    >
                        <template v-slot:placeholder>
                            <v-row class="fill-height grey ma-0" align="center" justify="center">
                                <v-progress-circular
                                    :width="2"
                                    :size="20"
                                    color="dark"
                                    indeterminate
                                ></v-progress-circular>
                            </v-row>
                        </template>
                    </v-img>
                </template>
                <template v-else>
                    <v-row class="rounded-lg fill-height full-width grey ma-0" align="center" justify="center">
                        <v-icon color="dark-grey" x-large>mdi-image-outline</v-icon>
                    </v-row>
                </template>
            </div>

            <b>{{ entry.data.entryCode }}</b>

            <template v-if="loading">
                <v-progress-circular
                    class="ml-auto"
                    :width="2"
                    :size="20"
                    color="dark"
                    indeterminate
                ></v-progress-circular>
            </template>

            <template v-else>
                <template v-if="completed">
                    <!-- <v-icon class="ml-auto" color="success">mdi-check-circle</v-icon> -->
                    <b class="success--text ml-auto">{{ score }}</b>
                </template>

                <template v-else-if="every">
                    <v-icon class="ml-auto" color="warning">mdi-progress-alert</v-icon>
                </template>

                <template v-else-if="some">
                    <v-icon class="ml-auto" color="blue">mdi-progress-check</v-icon>
                </template>

                <template v-else-if="!close">
                    <!-- <template v-if="close">
                        <v-btn class="ml-auto" icon small @click="deselect(entry)">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </template> -->

                    <!-- <template v-else> -->
                    <v-icon class="ml-auto">mdi-arrow-right</v-icon>
                    <!-- </template> -->
                </template>

                <!-- <template v-else> -->
                <v-btn
                    :class="completed || every || some ? 'ml-1' : 'ml-auto'"
                    icon
                    small
                    @click="deselect(entry)"
                    v-if="close"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <!-- </template> -->
            </template>
        </v-card>
    </div>
</template>

<script>
import Entry from "@/modules/app-module/entry.js";
import ScoreCard from "@/modules/app-module/score-card.js";

export default {
    name: "entry-item",

    props: {
        entry: {
            type: Object,
        },
        close: {
            type: Boolean,
        },
        room: {
            type: Object,
        },
    },

    data() {
        return {
            loading: false,
            scorecards: [],
        };
    },

    computed: {
        activeJudges() {
            return this.room?.data?.activeJudges || [];
        },
        judges() {
            let limit = this.room?.data?.limit || 0;
            let array = [];

            for (let index = 1; index <= limit; index++) {
                let judge = this.activeJudges.find((item) => item.chair == index);

                let score = null;

                if (judge) {
                    if (this.entry?._id) {
                        let scores = this.entry?.posts?.score?.posts || [];

                        score = scores.find(
                            (item) => item?.managedAuthor == judge?.judge?._id && item.data.round == this.round
                        );
                    }

                    array.push({ ...judge, score });
                } else {
                    array.push({
                        score: null,
                        judge: null,
                        chair: index,
                    });
                }
            }

            return array;
        },
        every() {
            return this.judges.every((judge) => judge?.score);
        },
        some() {
            return this.judges.some((judge) => judge?.score);
        },
        currentScoreCard() {
            let scorecards = this.scorecards || [];

            if (scorecards?.length) {
                return scorecards.sort((a, b) => b?.data?.round - a?.data?.round)[0];
                // return scorecards.find((card) => !card.data.completed && !card.data.contested);
            }

            return false;
        },
        round() {
            let scorecards = this.scorecards;

            scorecards = scorecards.filter((card) => card?.data?.contested);

            return scorecards.length + 1;
        },
        score() {
            return this.currentScoreCard?.data?.modified || this?.currentScoreCard?.data?.calculated || 0;
        },
        completed() {
            return this.currentScoreCard?.data?.completed || false;
        },
        contested() {
            return this.currentScoreCard?.data?.contested || false;
        },
    },

    methods: {
        async init() {
            this.loading = true;

            try {
                let query = {
                    _type: "article",
                    definition: "scoreCard",
                    "data.entry": `ObjectId(${this.entry._id})`,
                };

                this.scorecards = await ScoreCard.query(query).then(({ data }) => data);
            } catch (err) {
                console.log("ERROR", err);
            }

            this.loading = false;
        },
        getThumbnailFromEmbedUrl(embedUrl) {
            const regex = /^https:\/\/www\.youtube\.com\/embed\/(.*)$/;
            const match = embedUrl.match(regex);
            if (match) {
                console.log(`YT THUMB https://img.youtube.com/vi/${match[1]}/hqdefault.jpg `);
                return `https://img.youtube.com/vi/${match[1]}/hqdefault.jpg`;
            }

            // If URL is not in expected format, return null
            return null;
        },
        select(entry) {
            this.$emit("select", entry);
        },
        deselect(entry) {
            this.$emit("deselect", entry);
        },
        hasImage(entry) {
            let image = entry?.data?.image;

            return image?._id || image;
        },
    },

    async mounted() {
        await this.init();
    },
};
</script>

<style lang="scss"></style>
