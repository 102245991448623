<template>
    <div class="live-scribe full-height full-width overflow-hidden d-flex">
        <v-card
            class="rounded-0 background full-height full-width flex-shrink-0 elevation-0 d-flex flex-column overflow-hidden"
            width="300"
        >
            <template v-if="active && active._id">
                <v-card
                    class="elevation-0 d-flex align-center justify-space-between px-6 flex-shrink-0 transparent"
                    height="60"
                >
                    <h3>Current</h3>
                </v-card>

                <div class="mt-3 px-6 pb-6" :key="cache">
                    <entry-item :key="active._id" :room="room" :entry="active" @deselect="deselect" close></entry-item>
                </div>

                <hr class="ma-0" />
            </template>

            <div class="entries fill-height pa-6 pt-0 overflow-y-scroll hide-scrollbar">
                <v-card
                    class="elevation-0 d-flex align-center justify-space-between dflex-shrink-0 transparent"
                    height="60"
                >
                    <h3>Entries</h3>
                </v-card>

                <v-btn-toggle
                    class="justify-space-between full-width mb-3"
                    mandatory
                    v-model="toggleScores"
                    tile
                    color="primary"
                    group
                >
                    <v-btn class="rounded-lg ma-0" value="all"> All </v-btn>
                    <v-btn class="rounded-lg ma-0" value="74"> 74 </v-btn>
                    <v-btn class="rounded-lg ma-0" value="79"> 79 </v-btn>
                    <v-btn class="rounded-lg ma-0" value="89"> 89 </v-btn>
                </v-btn-toggle>

                <div class="d-flex align-center mb-3">
                    <v-text-field
                        dense
                        class="rounded-lg"
                        ref="search"
                        v-model="search"
                        solo
                        hide-details
                        placeholder="Search..."
                        clearable
                    ></v-text-field>

                    <!-- <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn class="ml-2" icon v-bind="attrs" v-on="on"><v-icon>mdi-dots-vertical</v-icon></v-btn>
                        </template>

                        <v-list class="list">
                            <v-list-item color="primary" class="navigation-inactive" active-class="navigation-active">
                                <v-list-item-content>
                                    <v-list-item-title>test</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-menu> -->
                </div>

                <div class="mt-3" v-for="entry in filtered" :key="entry._id" v-if="entry._id != active._id">
                    <entry-item :key="entry._id" :room="room" :entry="entry" @select="select"></entry-item>
                </div>

                <div class="mt-3" v-for="entry in complete" :key="entry._id" v-if="entry._id != active._id">
                    <entry-item :key="entry._id" :room="room" :entry="entry" @select="select"></entry-item>
                </div>
            </div>
            <div style="width: 100%">
                <v-row>
                    <v-expansion-panels accordion>
                        <v-expansion-panel :value="0">
                            <v-expansion-panel-header
                                ><p class="pa-0 ma-0">
                                    <v-icon>mdi-connection</v-icon> Connection Status
                                </p></v-expansion-panel-header
                            >
                            <v-expansion-panel-content>
                                <v-row>
                                    <v-col
                                        cols="6"
                                        class="pl-5 py-2 mx-0 text-left"
                                        v-for="member in roomMembers"
                                        :key="member.id"
                                    >
                                        <span :class="getStatusClass(member)"></span>
                                        {{ member.name }}
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-row>
            </div>
        </v-card>

        <v-card
            class="rounded-0 full-height full-width flex-shrink-1 elevation-0 d-flex align-center flex-column overflow-hidden"
        >
            <v-card
                class="elevation-0 d-flex align-center justify-space-between px-6 flex-shrink-0 full-width overflow-hidden"
                height="60"
            >
                <div class="d-flex align-center justify-space-between flex-shrink-1 overflow-hidden">
                    <v-btn
                        class="mr-3"
                        icon
                        small
                        @click="
                            $router.push({
                                name: 'live-competitions-id',
                                params: { competition: $route.params.competition, room: $route.params.room },
                            })
                        "
                    >
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>

                    <div class="text-overflow">
                        <b>
                            {{ competition.title }} //
                            <span class="primary--text">{{ room.title }}</span>
                        </b>
                    </div>
                </div>

                <div class="actions d-flex align-center">
                    <v-btn
                        class="ml-3"
                        icon
                        small
                        :to="{
                            name: 'live-competitions-display',
                            params: {
                                competition: $route.params.competition,
                                room: $route.params.room,
                                scribe: $route.params.scribe,
                            },
                        }"
                    >
                        <v-icon>mdi-open-in-new</v-icon>
                    </v-btn>

                    <v-btn class="ml-3" icon small @click="switchTheme()">
                        <v-icon v-html="$vuetify.theme.dark ? 'mdi-weather-sunny' : 'mdi-weather-night'"></v-icon>
                    </v-btn>

                    <v-btn class="ml-3" icon small @click="$router.push({ name: 'live-competitions' })">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </div>
            </v-card>

            <div class="overflow-hidden flex-shrink-1 full-height full-width">
                <template v-if="loading">
                    <div class="full-height full-width d-flex align-center justify-center">
                        <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
                    </div>
                </template>

                <template v-else>
                    <v-container class="full-width full-height overflow-hidden d-flex flex-column pa-6">
                        <template v-if="active && active._id">
                            <div
                                class="full-width mb-6 d-flex align-center overflow-hidden justify-space-between flex-shrink-0"
                            >
                                <div class="d-flex align-center">
                                    <b>Round {{ round }}</b>
                                    <v-btn
                                        class="light--text ml-3"
                                        :color="descriptionClicked ? 'primary' : 'red lighten-1'"
                                        small
                                        v-if="formatQuestions || hasDescription"
                                        @click="
                                            $refs.descriptionDialog.open(active, formatQuestions);
                                            descriptionClicked = true;
                                        "
                                    >
                                        <span>View Responses</span>
                                    </v-btn>
                                </div>

                                <div class="d-flex align-center">
                                    <!-- Contest -->
                                    <v-btn
                                        class="ml-3"
                                        color="error"
                                        small
                                        :loading="contesting"
                                        v-if="canContest"
                                        @click="contestScoreCard()"
                                        >Contest Score</v-btn
                                    >

                                    <!-- Calculate -->
                                    <v-btn
                                        class="ml-3"
                                        color="success"
                                        small
                                        :disabled="!all"
                                        :loading="creating"
                                        v-if="canCreate"
                                        @click="createScoreCard()"
                                        >Calculate Score</v-btn
                                    >

                                    <!-- Restart -->
                                    <v-btn
                                        class="ml-3 light--text"
                                        color="dark"
                                        :disabled="all || !some"
                                        small
                                        :loading="restarting"
                                        v-if="canCreate"
                                        @click="restartRound()"
                                        >Restart Round</v-btn
                                    >

                                    <!-- Finalise -->
                                    <v-btn
                                        class="ml-3"
                                        color="success"
                                        small
                                        :loading="finalising"
                                        :disabled="!valid"
                                        v-if="canFinalise"
                                        @click="finaliseScoreCard()"
                                        >Finalise Score</v-btn
                                    >

                                    <!-- Review -->
                                    <v-btn
                                        class="ml-3"
                                        color="primary"
                                        small
                                        v-if="canReview"
                                        @click="reviewScoreCard()"
                                        >Review Score</v-btn
                                    >
                                    <v-btn
                                        class="ml-3"
                                        color="success"
                                        small
                                        :loading="reviewing"
                                        :disabled="!valid"
                                        v-if="canSubmitReview"
                                        @click="openReviewDialog()"
                                        >Confirm Review</v-btn
                                    >
                                </div>
                            </div>
                            <template v-if="active.data.image && !active.data.videoSourceFull">
                                <v-img
                                    class="background rounded-lg full-width full-height flex-shrink-1"
                                    :src="$fluro.asset.imageUrl(active.data.image)"
                                    loading
                                    alt="avatar"
                                    contain
                                >
                                    <template v-slot:placeholder>
                                        <v-row class="fill-height background ma-0" align="center" justify="center">
                                            <v-progress-circular
                                                :width="2"
                                                :size="20"
                                                color="dark"
                                                indeterminate
                                            ></v-progress-circular>
                                        </v-row>
                                    </template>
                                </v-img>
                            </template>
                            <template v-else>
                                <div class="select-container">
                                    <v-select
                                        v-model="selectedVideo"
                                        :items="videos"
                                        align-center
                                        justify-center
                                        class="custom-select"
                                        item-text="label"
                                    ></v-select>
                                </div>

                                <iframe
                                    class="light full-width full-height cursor-pointer"
                                    v-if="videoSource"
                                    width="100%"
                                    height="315"
                                    :src="videoSource"
                                    title="YouTube video player"
                                    frameborder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                ></iframe>
                            </template>
                            <template v-if="currentScoreCard && !contested && !restarted">
                                <v-container class="pa-0 mt-6" fluid>
                                    <v-row>
                                        <v-col :cols="modifiedScore ? 3 : 4">
                                            <v-card
                                                height="135"
                                                class="elevation-0 text-center rounded-lg background pa-6 full-width d-flex flex-column align-center justify-center"
                                                v-if="currentScoreCard.data && currentScoreCard.data.round"
                                            >
                                                <p class="ma-0">Round</p>
                                                <h1>{{ currentScoreCard.data.round }}</h1>
                                            </v-card>
                                        </v-col>

                                        <v-col :cols="modifiedScore ? 3 : 4">
                                            <v-card
                                                height="135"
                                                class="elevation-0 text-center rounded-lg background pa-6 full-width d-flex flex-column align-center justify-center"
                                                v-if="currentScoreCard.data && currentScoreCard.data.average"
                                            >
                                                <p class="ma-0">Average Score</p>
                                                <h1>{{ currentScoreCard.data.average }}</h1>
                                            </v-card>
                                        </v-col>

                                        <v-col :cols="modifiedScore ? 3 : 4">
                                            <v-card
                                                height="135"
                                                class="elevation-0 text-center rounded-lg background pa-6 full-width d-flex flex-column align-center justify-center"
                                                v-if="currentScoreCard.data && currentScoreCard.data.calculated"
                                            >
                                                <p class="ma-0">Calculated Score</p>

                                                <template v-if="!editing">
                                                    <h1 class="p-relative">
                                                        <span>{{ currentScoreCard.data.calculated }}</span>

                                                        <v-btn
                                                            class="p-absolute left-40 center-y pa-2"
                                                            icon
                                                            small
                                                            @click="startEditing()"
                                                            v-if="!modifiedScore && !completed"
                                                        >
                                                            <v-icon>mdi-pencil-outline</v-icon>
                                                        </v-btn>
                                                    </h1>
                                                </template>

                                                <template v-else>
                                                    <v-text-field
                                                        ref="modified"
                                                        v-model="modified"
                                                        :rules="[rules.max]"
                                                        class="flex-shrink-1 flex-grow-0 field-modified"
                                                        solo
                                                        hide-details
                                                    ></v-text-field>
                                                </template>
                                            </v-card>
                                        </v-col>

                                        <v-col cols="3" v-if="modifiedScore">
                                            <v-card
                                                height="135"
                                                class="elevation-0 text-center rounded-lg background pa-6 full-width d-flex flex-column align-center justify-center"
                                            >
                                                <p class="ma-0">Modified Score</p>
                                                <h1>{{ modifiedScore }}</h1>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </template>

                            <template v-else>
                                <div class="tiles mt-3 flex-shrink-0">
                                    <div class="tile-outer" v-for="judge in judges" :key="judge.chair">
                                        <judge-item
                                            :judge="judge"
                                            @remove="remove"
                                            @select="$refs.judgeSelect.open(judge)"
                                        ></judge-item>
                                    </div>
                                </div>
                            </template>
                        </template>

                        <template v-else>
                            <div class="full-width full-height d-flex align-center justify-center">
                                <p class="text-center mb-0 dark-grey--text">
                                    <b>Please choose an entry to begin!</b>
                                </p>
                            </div>
                        </template>
                        <!-- Accordian to show connection status of judges -->
                    </v-container>
                </template>
            </div>
        </v-card>

        <judge-select-dialog
            ref="judgeSelect"
            :selectable="selectableJudges"
            :selected="activeJudges"
            @judge="selectJudge"
        ></judge-select-dialog>
        <alert-dialog ref="alertDialog" @confirm="confirmSelect" title="Are you sure?"></alert-dialog>
        <alert-dialog ref="finaliseDialog" @confirm="confirmFinalise" title="Finalise"></alert-dialog>
        <alert-dialog ref="contestDialog" @confirm="confirmContest" title="Contest"></alert-dialog>
        <alert-dialog ref="restartDialog" @confirm="confirmRestart" title="Restart"></alert-dialog>
        <alert-dialog ref="reviewDialog" @confirm="confirmReview" title="Review"></alert-dialog>
        <alert-dialog
            ref="descriptionDialog"
            @confirm="$refs.descriptionDialog.close()"
            title="Responses"
        ></alert-dialog>
        <!-- <judge-dialog ref="reviewDialog" @confirm="confirmReview" title="Review"></judge-dialog> -->
    </div>
</template>

<script>
import _ from "lodash";

import Room from "@/modules/app-module/room";
import Entry from "@/modules/app-module/entry";
import ScoreCard from "@/modules/app-module/score-card";

import EntryItem from "@/components/scribe/entry-item.vue";
import JudgeItem from "@/components/scribe/judge-item.vue";
import JudgeSelectDialog from "@/components/dialogs/judge-select-dialog.vue";
import AlertDialog from "@/components/dialogs/alert-dialog.vue";
// import JudgeDialog from "@/components/dialogs/judge-dialog.vue";

export default {
    name: "live-scribe",

    meta() {
        return {
            title: this.room?.title || "Room",
        };
    },

    props: {},

    components: {
        EntryItem,
        JudgeItem,
        JudgeSelectDialog,
        AlertDialog,
        // JudgeDialog,
    },

    data() {
        return {
            videos: [{ label: "Short Video" }, { label: "Full Video" }],
            selectedVideo: "Short Video", // default selection
            descriptionClicked: false,
            socket: null,
            toggleScores: "all",
            editing: false,
            creating: false,
            finalising: false,
            contesting: false,
            restarting: false,
            reviewing: false,
            loading: false,
            search: "",
            room: {},
            timeout: 5000,
            poll: null,
            cache: 0,
            modified: 0,
            // active: null,
            scorecards: [],
            entries: [],
            rules: {
                max: (v) => {
                    if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
                    return "Number has to be between 0 and 100";
                },
            },
            roomMembers: [],
        };
    },

    computed: {
        videoSource() {
            return this.selectedVideo == "Short Video"
                ? this.active.data.videoSourceShort
                : this.active.data.videoSourceFull;
        },
        formatQuestions() {
            const answers = this.active.data.questions;
            const compQuestions = this.active.data.competition.data.questions.map((item) => item.question);

            // if (!answers) return null;

            let matchedArray = [];

            if (this.active.data.body) {
                matchedArray.push({ question: "Description", answer: this.active.data.body });
            }
            console.log("BODY", this.active.data);
            if (answers) {
                for (let i = 0; i < compQuestions.length; i++) {
                    const question = compQuestions[i];
                    const answer = answers[i];

                    matchedArray.push({ question, answer });
                }
            }
            // Convert matchedArray to a string with HTML markup
            let matchedString = matchedArray
                .map((obj) => `<p><strong>${obj.question}</strong><br>${obj.answer}</p>`)
                .join("<br>");

            console.log("MATCHED ARRAY", matchedArray);
            console.log("MATCHED STRING", matchedString);

            return matchedString;
        },
        getStatusClass() {
            return function (member) {
                const currentTimestamp = Date.now();
                const timeSinceLastPing = currentTimestamp - member.lastPing;
                // Customize the logic based on your requirements
                if (timeSinceLastPing < 5000) {
                    // 10 seconds
                    return "indicator-green"; // CSS class for green indicator
                } else if (timeSinceLastPing < 10000) {
                    // 30 seconds
                    return "indicator-yellow"; // CSS class for yellow indicator
                } else {
                    return "indicator-red"; // CSS class for red indicator
                }
            };
        },
        valid() {
            if (this.editing) {
                let model = this.modified;

                if (!model) return false;

                if (model > 100 || model < 1) return false;

                if (isNaN(model)) return false;
            }

            return true;
        },

        ids() {
            return this?.room?.data?.entries.map((entry) => entry?._id);
        },
        competition() {
            return this.room?.data?.competition || {};
        },
        // category() {
        //     return this.room?.data?.category || {};
        // },
        // entries() {
        //     return this.room?.data?.entries.filter((entry) => entry._id !== this.active?._id) || [];
        // },
        limit() {
            return this?.room?.data?.limit || 0;
        },
        scores() {
            let scores = this.active?.posts?.score?.posts || [];

            scores = scores.filter((score) => score?.data?.round == this.round);

            return scores;
        },
        averageScore() {
            let scores = this.scores.map(({ data }) => data.score);
            let average = scores.reduce((a, b) => a + b, 0) / scores.length;

            return Math.round(average || 0);
        },
        calculatedScore() {
            // let scores = [83, 88, 85, 99, 99];

            // let average = scores.reduce((a, b) => a + b, 0) / scores.length;
            // average = Math.round(average || 0);

            // Get the scores
            let scores = this.scores.map(({ data }) => data.score);

            // Find the average
            let average = this.averageScore;

            // Set calculated default
            let calculated = average;

            //////////////////////////////////////////////////

            let trimmed = scores.map((score) => Math.floor(score / 10)); // [8, 8, 8, 9, 9]
            let majority = Math.ceil(scores.length / 2 + 0.1); // 3

            // Should return something like
            // {
            //     8: 3,
            //     9: 2
            // }
            let count = trimmed.reduce((acc, val) => {
                acc[val] = (acc[val] || 0) + 1;
                return acc;
            }, {});

            // Find the most occuring bracket
            let bracket = trimmed.find((num) => count[num] >= majority);

            // Return the average if there is no bracket
            if (!bracket) return average;

            //////////////////////////////////////////////////

            let averageTrimmed = Math.floor(average / 10);

            // If the average is in the bracket, return the average
            if (averageTrimmed == bracket) return average;

            // If it's less, bump the score up. ie. 87 => 90
            if (averageTrimmed < bracket) calculated = bracket * 10;

            // If it's higher, bump the score down. ie. 91 => 89
            if (averageTrimmed > bracket) calculated = (bracket + 1) * 10 - 1;

            return calculated;
        },
        modifiedScore() {
            return this?.currentScoreCard?.data?.modified;
        },
        initialFiltered() {
            let entries = this.entries || [];

            if (this.search?.length) {
                let keywords = this.search.toLowerCase();

                entries = entries.filter((entry) => {
                    let code = entry?.data?.entryCode?.toLowerCase();

                    return code.includes(keywords);
                });
            }

            if (this.toggleScores != "all") {
                entries = entries.filter((entry) => {
                    let scorecards = this.scorecards?.filter((item) => item?.data?.entry?._id == entry?._id) || [];

                    switch (this.toggleScores) {
                        case "all":
                            return true;

                        default:
                            return scorecards.some(
                                (scorecard) =>
                                    (scorecard?.data?.modified || scorecard?.data?.calculated) == this.toggleScores
                            );
                    }
                });
            }

            return entries;
        },
        filtered() {
            let filtered = this.initialFiltered || [];

            filtered = filtered
                .filter((item) => {
                    let scores = item?.posts?.score?.posts || [];

                    return scores.length < this.limit;
                })
                .sort((a, b) => {
                    return this.ids.indexOf(a._id) - this.ids.indexOf(b._id);
                });

            return filtered;
        },
        complete() {
            let filtered = this.initialFiltered || [];

            filtered = filtered
                .filter((item) => {
                    let scores = item?.posts?.score?.posts || [];

                    return scores.length >= this.limit;
                })
                .sort((a, b) => {
                    return this.ids.indexOf(a._id) - this.ids.indexOf(b._id);
                });

            return filtered;
        },
        active() {
            // return this.filtered.find((item) => item._id == this?.room?.data?.active?._id) || this.complete.find((item) => item._id == this?.room?.data?.active?._id) || {};
            return this.entries.find((item) => item?._id == this?.room?.data?.active?._id) || {};
        },
        filteredScoreCards() {
            return this.scorecards?.filter((item) => item?.data?.entry?._id == this.active?._id) || [];
        },
        round() {
            let scorecards = this.filteredScoreCards;
            // let scorecards = this.scorecards;

            scorecards = scorecards.filter((card) => card?.data?.contested || card?.data?.restarted);

            return scorecards.length + 1;
        },
        currentScoreCard() {
            let scorecards = this.filteredScoreCards;
            // let scorecards = this.scorecards || [];

            if (scorecards?.length) {
                return scorecards.sort((a, b) => b?.data?.round - a?.data?.round)[0];
                // return scorecards.find((card) => !card.data.completed && !card.data.contested);
            }

            return false;
        },
        selectableJudges() {
            return this.room?.data?.selectableJudges || [];
        },
        activeJudges() {
            return this.room?.data?.activeJudges || [];
        },
        judges() {
            let limit = this.room?.data?.limit || 0;
            let array = [];

            for (let index = 1; index <= limit; index++) {
                let judge = this.activeJudges.find((item) => item.chair == index);

                let score = null;
                let prevScore = null;
                console.log("judge", judge);
                if (judge) {
                    console.log("Jere");
                    if (this.active?._id) {
                        let scores = this.active?.posts?.score?.posts || [];
                        console.log(scores, "Scores");
                        score = scores.find((item) => {
                            console.log(item, "item");
                            return item?.managedAuthor == judge?.judge?._id && item.data.round == this.round;
                        });
                        prevScore = scores.find(
                            (item) => item?.managedAuthor == judge?.judge?._id && item.data.round == this.round - 1
                        );
                    }

                    array.push({ ...judge, score, prevScore });
                } else {
                    array.push({
                        score: null,
                        judge: null,
                        chair: index,
                    });
                }
            }
            console.log("ACTIVATED", array);
            return array;
        },
        all() {
            return this.judges.every((judge) => judge?.score);
        },
        some() {
            return this.judges.some((judge) => judge?.score);
        },
        completed() {
            return this.currentScoreCard?.data?.completed;
        },
        contested() {
            return this.currentScoreCard?.data?.contested;
        },
        restarted() {
            return this.currentScoreCard?.data?.restarted;
        },
        canCreate() {
            return !this.currentScoreCard || this.contested || this.restarted;
        },
        canContest() {
            return this.currentScoreCard && !this.contested && !this.completed && !this.restarted;
        },
        canFinalise() {
            return this.currentScoreCard && !this.contested && !this.completed && !this.restarted;
        },
        canReview() {
            return this.currentScoreCard && this.completed && !this.editing && !this.modifiedScore;
        },
        canSubmitReview() {
            return this.currentScoreCard && this.completed && this.editing && !this.modifiedScore;
        },
        hasDescription() {
            return this.active?.data?.body?.length;
        },
    },

    methods: {
        async init() {
            this.loading = true;

            try {
                this.room = await Room.get(this.$route.params.room).then(({ data }) => data);
                console.log("ROOM", this.room);

                //////////////////////////////////////////////////

                await this.setup();
            } catch (err) {
                console.log("ERROR", err);
            }
        },
        /////////////////////////START SOCKET HANDELING////////////////////////////
        async socketSetup() {
            this.socket = new WebSocket(
                `wss://smhb2elzv9.execute-api.ap-southeast-2.amazonaws.com/production?persona=${this.$app.user.persona}`
            );

            this.socket.onopen = () => {
                console.log("Connected to WebSocket.", this.$route.params.room);
                // Send a create command/message to the server
                const createMessage = {
                    command: "create",
                    room: this.$route.params.room,
                    persona: this.$app.user.persona,
                    name: "scribe",
                };
                this.socket.send(JSON.stringify(createMessage));
                this.startPing();
            };

            this.socket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log("GOT ACTION", data.action);
                if (data.action == "submit") {
                    this.setup("submit"); //Pass submit in to bypass the refresh of all sockets
                } else if (data.action == "pingScribe" || data.action == "ping") {
                    this.updateConnection(data.name);
                }
            };

            this.socket.onclose = () => {
                console.log("Disconnected from WebSocket.");
                this.stopPing();
            };

            this.socket.onerror = (error) => {
                console.error("WebSocket error: ", error);
                this.stopPing();
            };
        },
        closeWebSocket() {
            if (this.socket) {
                this.socket.close();
                this.socket = null;
            }
        },
        startPing() {
            this.pingInterval = setInterval(() => {
                if (this.socket && this.socket.readyState === WebSocket.OPEN) {
                    const createMessage = {
                        command: "ping",
                        persona: this.$app.user.persona,
                        name: "Scribe",
                    };
                    this.socket.send(JSON.stringify(createMessage));
                }
            }, 1000); // Ping interval in milliseconds
        },
        stopPing() {
            clearInterval(this.pingInterval);
            this.pingInterval = null;
        },
        //Sends a message out to all sockets to refresh
        socketRestart() {
            const persona = this.$app.user.persona;
            if (persona) {
                const message = {
                    command: "refresh",
                    room: this.$route.params.room,
                    persona: persona,
                    name: "scribe",
                };
                this.socket.send(JSON.stringify(message));
            } else {
                console.warn("NO PERSONA, FAILED TO SEND");
            }
            this.descriptionClicked = false;
        },
        updateConnection(name) {
            const memberIndex = this.roomMembers.findIndex((member) => member.name === name);
            if (memberIndex !== -1) {
                // Member exists, update lastPing time
                this.roomMembers[memberIndex].lastPing = Date.now(); //Weird timeoffset
            } else {
                // Member doesn't exist, add them to the array
                const newMember = {
                    id: this.roomMembers.length + 1,
                    name: name,
                    lastPing: Date.now(),
                };
                this.roomMembers.push(newMember);
            }
        },
        /////////////////////////END SOCKET HANDELING////////////////////////////
        async setup(action = "") {
            // clearTimeout(this.poll);

            await this.$fluro.resetCache();

            let user = this.$app.user;
            let scribe = this.room?.data?.scribe;
            let scribeId = this.$route.params.scribe;

            if (user.persona == scribe._id && user.persona == scribeId) {
                await this.refreshScores();
                await this.refreshScoreCard();

                // this.poll = setTimeout(this.setup, this.timeout);
            } else {
                this.$router
                    .push({
                        name: "live-competitions-room",
                        params: {
                            competition: this.$route.params.competition,
                            // category: this.$route.params.category,
                            room: this.$route.params.room,
                        },
                    })
                    .catch(() => {});
            }
            if (!action) {
                if (this.socket) {
                    this.socketRestart();
                } else {
                    this.socketSetup();
                }
            }

            this.loading = false;
        },
        async refreshScores() {
            try {
                let query = {
                    _type: "article",
                    definition: "entry",
                    _id: {
                        $in: this.ids,
                    },
                };

                this.entries = await Entry.query(query, { params: { appendPosts: "all" } }).then(({ data }) => data);
                // console.log("ENTRIES", this.entries);
            } catch (err) {
                console.log("REFRESH ERROR", err);
            }
        },
        async refreshScoreCard() {
            try {
                let query = {
                    _type: "article",
                    definition: "scoreCard",
                    // "data.entry": `ObjectId(${this.active._id})`,
                    "data.room": `ObjectId(${this.room._id})`,
                };

                this.scorecards = await ScoreCard.query(query).then(({ data }) => data);
                // console.log("SCORE CARDS", this.scorecards);
            } catch (err) {
                console.log("REFRESH SCORE CARD ERROR", err);
            }
        },
        async select(entry) {
            if (!this.all && this.active._id) {
                this.$refs.alertDialog.open(
                    entry,
                    `The judges have not finished scoring, are you sure you want to move on?`
                );
            } else if ((this.canCreate || this.canFinalise) && this.active._id) {
                this.$refs.alertDialog.open(
                    entry,
                    `You have not finalised this entry, are you sure you want to move on?`
                );
            } else {
                try {
                    await this.confirmSelect(entry);
                } catch (err) {
                    console.log("ERROR", err);
                }
            }
        },
        async deselect(entry) {
            // if (!this.all && this.active._id) {
            //     this.$refs.alertDialog.open(entry, `The judges have not finished scoring, are you sure you want to move on?`);
            // } else if ((this.canCreate || this.canFinalise) && this.active._id) {
            //     this.$refs.alertDialog.open(entry, `You have not finalised this entry, are you sure you want to move on?`);
            // } else {
            //     try {
            //         await this.confirmDeselect(entry);
            //     } catch (err) {
            //         console.log("ERROR", err);
            //     }
            // }

            try {
                await this.confirmDeselect(entry);
            } catch (err) {
                console.log("ERROR", err);
            }
        },
        async confirmSelect(entry) {
            this.editing = false;
            this.loading = true;

            try {
                const payload = {
                    data: {
                        active: entry._id,
                    },
                };

                this.room = await Room.update(this.$route.params.room, payload).then(({ data }) => data);
                console.log("CONFIRM SELECT", this.room);

                this.$refs.alertDialog.close();

                this.search = "";

                //////////////////////////////////////////////////
                await this.setup();
            } catch (err) {
                console.log("ERROR", err);
                this.$refs.alertDialog.error(err);
            }
        },
        async confirmDeselect(entry) {
            this.loading = true;
            try {
                const payload = {
                    data: {
                        active: null,
                    },
                };

                this.room = await Room.update(this.$route.params.room, payload).then(({ data }) => data);
                console.log("CONFIRM DESELECT", this.room);

                this.$refs.alertDialog.close();

                this.search = "";

                //////////////////////////////////////////////////
                await this.setup();
            } catch (err) {
                console.log("ERROR", err);
                this.$refs.alertDialog.error(err);
            }
        },
        async selectJudge(selected, index) {
            try {
                let activeJudges = _.cloneDeep(this.activeJudges);

                activeJudges.splice(index, 0, {
                    judge: selected._id,
                    chair: index,
                });

                const payload = {
                    data: {
                        activeJudges,
                    },
                };

                this.room = await Room.update(this.$route.params.room, payload).then(({ data }) => data);
                console.log("SELECTED JUDGE", this.room);
                const persona = this.$app.user.persona;
                if (persona) {
                    const message = {
                        command: "refresh",
                        room: this.$route.params.room,
                        persona: persona,
                        name: "scribe",
                    };
                    this.socket.send(JSON.stringify(message));
                }
                this.$refs.judgeSelect.close();
            } catch (err) {
                console.log("ERROR", err);
                this.$refs.judgeSelect.error(err);
            }
        },
        async remove(judge) {
            try {
                let activeJudges = _.cloneDeep(this.activeJudges);

                let index = activeJudges.findIndex((item) => item?.judge?._id == judge?.judge?._id);

                activeJudges.splice(index, 1);

                const payload = {
                    data: {
                        activeJudges,
                    },
                };

                this.room = await Room.update(this.$route.params.room, payload).then(({ data }) => data);
                const persona = this.$app.user.persona;
                if (persona) {
                    const message = {
                        command: "refresh",
                        room: this.$route.params.room,
                        persona: persona,
                        name: "scribe",
                    };
                    this.socket.send(JSON.stringify(message));
                }

                console.log("REMOVED JUDGE", this.room);
            } catch (err) {
                console.log("ERROR", err);
            }
        },
        async createScoreCard() {
            this.creating = true;

            try {
                let competition = this.competition;
                let sandbox = competition.realms.some(
                    (realm) => realm?._id == this.$app.sandboxRealm || realm == this.$app.sandboxRealm
                );

                const payload = {
                    title: `Score Card for ${this.active.title}`,
                    realms: [sandbox ? this.$app.sandboxRealm : this.$app.liveRealm],
                    data: {
                        room: this.room._id,
                        entry: this.active._id,
                        round: this.round || 0,
                        scores: this.scores,
                        average: this.averageScore,
                        calculated: this.calculatedScore,
                    },
                };

                console.log(payload.data);

                let scorecard = await ScoreCard.create(payload).then(({ data }) => data);
                console.log("SCORE CARD", scorecard);

                this.loading = true;

                this.refresh();
                await this.setup();
            } catch (err) {
                console.log("SCORE CARD ERROR", err);
            }

            this.creating = false;
        },
        restartRound() {
            this.$refs.restartDialog.open(
                null,
                `Are you sure you want to restart this round? A record of this round will be kept and we will move on to <b>Round ${
                    this.round + 1
                }</b>.`
            );
        },

        async confirmRestart() {
            this.restarting = true;

            try {
                let competition = this.competition;
                let sandbox = competition.realms.some(
                    (realm) => realm?._id == this.$app.sandboxRealm || realm == this.$app.sandboxRealm
                );

                const payload = {
                    title: `Score Card for ${this.active.title}`,
                    realms: [sandbox ? this.$app.sandboxRealm : this.$app.liveRealm],
                    data: {
                        room: this.room._id,
                        entry: this.active._id,
                        round: this.round || 0,
                        scores: this.scores,
                        average: this.averageScore,
                        calculated: this.calculatedScore,
                        restarted: true,
                    },
                };

                let scorecard = await ScoreCard.create(payload).then(({ data }) => data);
                console.log("RESTARTED", scorecard);
                this.$refs.restartDialog.close();

                this.loading = true;

                this.refresh();

                await this.setup();
            } catch (err) {
                console.log("RESTART ERROR", err);
                this.$refs.restartDialog.error(err);
            }

            this.restarting = false;
        },
        finaliseScoreCard() {
            this.$refs.finaliseDialog.open(
                null,
                "Are you sure you want to finalise this score card? You will not be able to contest it again."
            );
        },
        async confirmFinalise() {
            this.finalising = true;

            try {
                const payload = {
                    data: {
                        completed: true,
                    },
                };

                let calculated = this.currentScoreCard?.data?.calculated;

                if (this.editing && calculated && parseInt(calculated) != parseInt(this.modified)) {
                    payload.data.modified = parseInt(this.modified);
                }

                let scorecard = await ScoreCard.update(this.currentScoreCard._id, payload).then(({ data }) => data);
                console.log("FINAL SCORE CARD", scorecard);

                this.$refs.finaliseDialog.close();

                this.loading = true;

                this.refresh();
                await this.setup();

                this.editing = false;
            } catch (err) {
                console.log("FINALISE ERROR", err);
                this.$refs.finaliseDialog.error(err);
            }

            this.finalising = false;
        },
        contestScoreCard() {
            this.$refs.contestDialog.open(
                null,
                "Are you sure you want to contest this score card? This will start a new round of judging."
            );
        },
        async confirmContest() {
            this.contesting = true;

            try {
                const contestPayload = {
                    data: {
                        contested: true,
                    },
                };

                let scorecard = await ScoreCard.update(this.currentScoreCard._id, contestPayload).then(
                    ({ data }) => data
                );
                console.log("CONTESTED SCORE CARD", scorecard);

                this.$refs.contestDialog.close();

                this.loading = true;

                this.refresh();
                await this.setup();
            } catch (err) {
                console.log("CONTEST ERROR", err);
                this.$refs.contestDialog.error(err);
            }

            this.contesting = false;
        },
        reviewScoreCard() {
            let { data } = this.currentScoreCard;

            this.modified = data.modified || data.calculated;

            this.editing = true;

            this.$nextTick(() => {
                this.$refs.modified.focus();
            });
        },
        openReviewDialog() {
            this.$refs.reviewDialog.open(
                null,
                "Are you sure you are ready to submit this review? You will not be able to do it again."
            );
        },
        async confirmReview() {
            this.reviewing = true;

            try {
                let calculated = this.currentScoreCard?.data?.calculated;

                if (parseInt(calculated) != parseInt(this.modified)) {
                    const reviewPayload = {
                        data: {
                            reviewed: true,
                            modified: parseInt(this.modified),
                        },
                    };

                    let scorecard = await ScoreCard.update(this.currentScoreCard._id, reviewPayload).then(
                        ({ data }) => data
                    );
                    console.log("REVIEWED SCORE CARD", scorecard);
                }

                this.$refs.reviewDialog.close();

                this.loading = true;

                this.refresh();
                await this.setup();

                this.editing = false;
            } catch (err) {
                console.log("REVIEW ERROR", err);
                this.$refs.reviewDialog.error(err);
            }

            this.reviewing = false;
        },
        switchTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            window.localStorage["dark"] = this.$vuetify.theme.dark;
        },
        refresh() {
            this.cache++;
        },
        startEditing() {
            this.editing = true;
            this.modified = this.currentScoreCard?.data?.calculated || 0;
        },
    },

    async mounted() {
        await this.init();
    },
    destroyed() {
        clearTimeout(this.poll);
        this.closeWebSocket();
    },
};
</script>

<style lang="scss">
.live-scribe {
    .tiles {
        overflow-x: scroll;
        overflow-y: hidden;
        height: 16vh;
        width: 100%;
        display: flex;

        .tile-outer {
            width: 100%;
            padding: 12px;
            flex: 0 0 calc(100% / 5);
            max-width: calc(100% / 5);
        }
    }

    .field-modified {
        .v-input__control {
            .v-input__slot {
                .v-text-field__slot {
                    input {
                        text-align: center;
                        font-size: 2em;
                        font-weight: 700;
                    }
                }
            }
        }

        &.error--text {
            input {
                color: var(--v-error-base) !important;
            }
        }
    }
}

.indicator-green {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(45, 201, 55);
    display: inline-block;
}

.indicator-yellow {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(219, 123, 43);
    display: inline-block;
}

.indicator-red {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(204, 50, 50);
    display: inline-block;
}
</style>
