<template>
    <v-dialog v-model="display" content-class="confirm" :fullscreen="$vuetify.breakpoint.xsOnly" width="600">
        <v-card class="judge-select-dialog no-selection">
            <div class="dialog-error error px-4" :class="{ active: showError }">
                <div class="text d-flex align-center">
                    <v-icon class="mr-4" color="white">mdi-alert-circle-outline</v-icon>
                    <b class="white--text small--text">{{ errorMessage }}</b>
                </div>

                <v-btn x-small icon fab color="white" @click="showError = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>

            <div class="pa-3">
                <p class="text-center mb-0"><b v-text="title"></b></p>
            </div>

            <v-container fluid>
                <v-row>
                    <v-col cols="12" sm="4" v-for="item in filtered" :key="item._id">
                        <v-card class="background pa-6 elevation-0 rounded-lg d-flex align-center flex-column" @click="select(item)">
                            <v-avatar class="rounded-lg light mb-3">
                                <v-icon>mdi-account-outline</v-icon>
                            </v-avatar>

                            <b class="text-center">{{ item.title }}</b>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>

            <v-overlay color="light" opacity="0.85" class="p-absolute" v-if="loading">
                <v-progress-circular :width="3" color="primary" indeterminate></v-progress-circular>
            </v-overlay>
        </v-card>
    </v-dialog>
</template>

<script>
// Packages
import _ from "lodash";

export default {
    name: "judge-select-dialog",

    props: {
        title: {
            type: String,
            default() {
                return "Select Judge";
            },
        },
        actionLabel: {
            type: String,
            default() {
                return "OK";
            },
        },
        cancelLabel: {
            type: String,
            default() {
                return "Cancel";
            },
        },
        actionColor: {
            type: String,
            default() {
                return "primary";
            },
        },
        cancelColor: {
            type: String,
            default() {
                return "grey";
            },
        },
        selectable: {
            type: Array,
            default() {
                return [];
            },
        },
        selected: {
            type: Array,
            default() {
                return [];
            },
        },
        hideAction: Boolean,
        hideCancel: Boolean,
    },

    data() {
        return {
            display: false,
            loading: false,
            showError: false,
            message: "",
            errorMessage: "",
            model: null,
            rules: {
                max: (v) => {
                    if (!isNaN(parseFloat(v)) && v >= 0 && v <= 100) return true;
                    return "Number has to be between 0 and 100";
                },
            },
        };
    },

    computed: {
        filtered() {
            return this.selectable.filter((judge) => !this.selected.some((selected) => selected?.judge?._id == judge._id));
        },
    },

    methods: {
        init(model) {
            this.showError = false;
            this.loading = false;

            if (!model) {
                this.model = null;
            } else {
                let clonedModel = _.cloneDeep(model);
                this.model = clonedModel;
            }
        },
        open(model) {
            this.init(model);
            this.display = true;
        },
        select(model) {
            this.loading = true;
            this.$emit("judge", model, this.model.chair);
        },
        error(message) {
            this.errorMessage = message;
            this.showError = true;
            this.loading = false;
        },
        close() {
            this.display = false;
            this.model = null;
        },
    },
};
</script>

<style lang="scss">
.confirm {
    background: transparent !important;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1) !important;
}

.judge-select-dialog {
    .actions {
        display: flex;
        align-items: center;

        .v-btn {
            width: 100%;
            flex-shrink: 1;
        }
    }
}
</style>
